



























import { Component, Vue, Prop } from 'vue-property-decorator'
import LabelWrap from './LabelWrap.vue'
import TextFieldBase from './TextFieldBase'

@Component({
  components: {
    LabelWrap
  }
})
export default class TextField extends TextFieldBase {}
