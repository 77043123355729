











import { CourtColorsHash } from '@/helpers/CourtColors'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { MultiViewerData, MultiViewerOptions, MultiViewerSize } from '../MultiViewer.vue'
@Component({})
export default class PickleballCourtView extends Vue {
  @Prop({ default: false }) fill!: boolean
  @Prop({ default: false }) lines!: boolean
  @Prop({ default: () => [0, 0] })
  ptx!: number[]
  @Prop({ default: () => {
      return { w: 30, h: 60 }
    }
  })
  size!: MultiViewerSize
  @Prop({ default: () => {
      return { x: 0, y:0, w: 30, h: 60 }
    }
  })
  trans!: MultiViewerSize
  @Prop({ default: () => {
      return {
        bg: CourtColorsHash.none,
        bbin: CourtColorsHash.none,
        ptln: CourtColorsHash.none,
        key: CourtColorsHash.none,
        keyc: CourtColorsHash.none,
        pkz: CourtColorsHash.none,
        pkk: CourtColorsHash.none,
        tnin: CourtColorsHash.none
      }
    }
  })
  zones!: MultiViewerData
  @Prop({ default: () => {
      return { north: 1, south: 1, halfCourt: 1, key: 1, keyTop: 1, ptln: 1 }
    }
  })
  options!: MultiViewerOptions
  tran = 'transparent'
  getFill(zone) {
    return zone && this.fill ? zone.value : this.tran
  }
  getStroke(zone) {
    return zone && this.lines ? zone.stroke : this.tran
  }
}
