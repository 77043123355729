


































































































































import { Component, Vue } from 'vue-property-decorator'
import Card from '../../shared/src/components/Card.vue'
import Col from '../../shared/src/components/Col.vue'
import Row from '../../shared/src/components/Row.vue'
import Btn from '../../shared/src/components/Btn.vue'
import Logo from '../components/Logo.vue'
import Checkbox from '../../shared/src/components/Checkbox.vue'
import TextField from '../../shared/src/components/TextField.vue'
import ContentRow from '../components/ContentRow.vue'
import Sizzle from '../components/Sizzle.vue'
import MultiCourtViewer from '../components/MultiViewer/MultiViewer.vue'
// import PickleballBasketballCourtViewer from '../components/PickleballBasketballViewer.vue'
import { CourtColorsHash } from '@/helpers/CourtColors'
import extend from '../../shared/src/helpers/extend'

@Component({
  components: {
    Sizzle,
    Card,
    Col,
    Row,
    Btn,
    Logo,
    Checkbox,
    TextField,
    ContentRow,
    MultiCourtViewer,
    // PickleballBasketballCourtViewer
  }
})
export default class MultiCourt extends Vue {
  angle = 'flat'
  zones
  size = { w: 50, h: 84 }
  colors = CourtColorsHash
  options = { north: 1, south: 1, east: 0, west: 0,  halfCourt: 1, key: 1, keyTop: 1, ptln: 1, pkb: 1, tns: 0, ms: 1 }
  _colorize
  combos = [
    ['gg', 'sb', 'wl'],
    ['gg', 'sb', 'bl'],
    ['sb', 'bl', 'wl'],
    ['sb', 'bl', 'sd'],
    ['sb', 'bl', 'tc'],
    ['sb', 'bl', 'gy'],
    ['sb', 'bl', 'cg'],
    ['tc', 'by', 'wl'],
    ['tc', 'by', 'cv']
  ]

  constructor() {
    super()
    this.colorize()
  }

  reset(type): void {
    if (type === 'basketball') {
      this.size = { w: 50, h: 84 }
      this.options = { north: 1, south: 1, east: 0, west: 0,  halfCourt: 1, key: 1, keyTop: 1, ptln: 1, pkb: 1, tns: 0, ms: 1 }
    } else if (type === 'tennis') {
      this.size = { w: 60, h: 120 }
      this.options = { north: 0, south: 0, east: 0, west: 0, halfCourt: 0, key: 1, keyTop: 1, ptln: 1, pkb: 1, tns: 1, ms: 1 }
    } else if (type === 'pickleball') {
      this.size = { w: 30, h: 60 }
      this.options = { north: 0, south: 0, east: 1, west: 0, halfCourt: 0, key: 1, keyTop: 1, ptln: 1, pkb: 1, tns: 0, ms: 1 }
    }
    this.colorize(this._colorize)
  }
  colorize(clrs?: string[]): void {
    if (clrs) {
      this._colorize = clrs.slice()
      const cls = [CourtColorsHash[clrs[0]], CourtColorsHash[clrs[1]], extend({}, CourtColorsHash[clrs[2] || 'wl'])]
      if (cls[2].value !== 'transparent') {
        cls[2].stroke = cls[2].value
        cls[2].value = 'tranparent'
      }
      this.zones = {
        bg: cls[0],

        bbin: this.options.tns || this.options.pkb ? cls[2] : cls[1],
        key: this.options.tns || this.options.pkb ? cls[2] : cls[1],
        keyc: this.options.tns || this.options.pkb ? cls[2] : cls[1],
        ptln: this.options.tns || this.options.pkb ? cls[2] : cls[1],

        pkin: !this.options.tns ? cls[1] : cls[2],
        pkz: this.options.tns ? cls[2] : cls[1],
        pkk: this.options.tns ? cls[2] : cls[1],

        tnin: cls[1]
      }
      this.$forceUpdate()
    } else {
      this._colorize = ['no', 'no', 'no']
      this.zones = {
        bg: CourtColorsHash.none,

        bbin: CourtColorsHash.none,
        key: CourtColorsHash.none,
        keyc: CourtColorsHash.none,
        ptln: CourtColorsHash.none,

        pkin: CourtColorsHash.none,
        pkz: CourtColorsHash.none,
        pkk: CourtColorsHash.none,

        tnin: CourtColorsHash.none
      }
    }
  }
}
