







import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Checkbox extends Vue {
  @Prop({ default: false }) value!: boolean
  @Prop({ default: 'py-1 px-2 h-10 items-center inline-flex' }) cls!: string

  get val(): boolean {
    return this.value
  }
  set val(v: boolean) {
    if (v !== this.value) {
      this.$emit('input', v)
    }
  }
}
