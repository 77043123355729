export const DAY = 1000 * 60 * 60 * 24
export const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
export const longMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
export const shortDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
export const longDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

// defining patterns
const replaceChars: { [key: string]: (t: Date) => string | number } = {
  // Day
  d: (t) => (t.getDate() < 10 ? '0' : '') + t.getDate(),
  D: (t) => shortDays[t.getDay()],
  j: (t) => t.getDate(),
  l: (t) => longDays[t.getDay()],
  N: (t) => (t.getDay() == 0 ? 7 : t.getDay()),
  S: (t) => {
    const d = t.getDate()
    return d % 10 == 1 && d != 11 ? 'st' : d % 10 == 2 && d != 12 ? 'nd' : d % 10 == 3 && d != 13 ? 'rd' : 'th'
  },
  w: (t) => t.getDay(),
  z: (t) => {
    const d = new Date(t.getFullYear(), 0, 1)
    return Math.ceil((t.getTime() - d.getTime()) / 86400000)
  }, // Fixed now
  // Week
  W: (t) => {
    const target = new Date(t.valueOf())
    const dayNr = (t.getDay() + 6) % 7
    target.setDate(target.getDate() - dayNr + 3)
    const firstThursday = target.valueOf()
    target.setMonth(0, 1)
    if (target.getDay() !== 4) {
      target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7))
    }
    return 1 + Math.ceil((firstThursday - target.valueOf()) / 604800000)
  },
  // Month
  F: (t) => longMonths[t.getMonth()],
  m: (t) => (t.getMonth() < 9 ? '0' : '') + (t.getMonth() + 1),
  M: (t) => shortMonths[t.getMonth()],
  n: (t) => t.getMonth() + 1,
  t: (t) => {
    let year = t.getFullYear(),
      nextMonth = t.getMonth() + 1
    if (nextMonth === 12) {
      year = year++
      nextMonth = 0
    }
    return new Date(year, nextMonth, 0).getDate()
  },
  // Year
  L: (t) => {
    const year = t.getFullYear()
    return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0) ? 1 : 0
  }, // Fixed now
  o: (t) => {
    const d = new Date(t.valueOf())
    d.setDate(d.getDate() - ((t.getDay() + 6) % 7) + 3)
    return d.getFullYear()
  }, //Fixed now
  Y: (t) => t.getFullYear(),
  y: (t) => ('' + t.getFullYear()).substr(2),
  // Time
  a: (t) => (t.getHours() < 12 ? 'am' : 'pm'),
  A: (t) => (t.getHours() < 12 ? 'AM' : 'PM'),
  B: (t) =>
    Math.floor(((((t.getUTCHours() + 1) % 24) + t.getUTCMinutes() / 60 + t.getUTCSeconds() / 3600) * 1000) / 24), // Fixed now
  g: (t) => t.getHours() % 12 || 12,
  G: (t) => t.getHours(),
  h: (t) => ((t.getHours() % 12 || 12) < 10 ? '0' : '') + (t.getHours() % 12 || 12),
  H: (t) => (t.getHours() < 10 ? '0' : '') + t.getHours(),
  i: (t) => (t.getMinutes() < 10 ? '0' : '') + t.getMinutes(),
  s: (t) => (t.getSeconds() < 10 ? '0' : '') + t.getSeconds(),
  u: (t) => {
    const m = t.getMilliseconds()
    return (m < 10 ? '00' : m < 100 ? '0' : '') + m
  },
  // Timezone
  e: (t) => /\((.*)\)/.exec(t.toString())?.[1] || '',
  I: (t) => {
    let DST: number | null = null
    for (let i = 0; i < 12; ++i) {
      const d = new Date(t.getFullYear(), i, 1)
      const offset = d.getTimezoneOffset()

      if (DST === null) DST = offset
      else if (offset < DST) {
        DST = offset
        break
      } else if (offset > DST) break
    }
    return t.getTimezoneOffset() === DST ? 1 : 0
  },
  O: (t) =>
    (-t.getTimezoneOffset() < 0 ? '-' : '+') +
    (Math.abs(t.getTimezoneOffset() / 60) < 10 ? '0' : '') +
    Math.abs(t.getTimezoneOffset() / 60) +
    '00',
  P: (t) =>
    (-t.getTimezoneOffset() < 0 ? '-' : '+') +
    (Math.abs(t.getTimezoneOffset() / 60) < 10 ? '0' : '') +
    Math.abs(t.getTimezoneOffset() / 60) +
    ':00', // Fixed now
  T: (t) => t.toTimeString().replace(/^.+ \(?([^)]+)\)?$/, '$1'),
  Z: (t) => -t.getTimezoneOffset() * 60,
  // Full Date/Time
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  c: (t) => formatDate(t, 'Y-m-d\\TH:i:sP'), // Fixed now
  r: (t) => t.toString(),
  U: (t) => t.getTime() / 1000
}

export const getWeek = (t: Date) => {
  const onejan = new Date(t.getFullYear(), 0, 1)
  const millisecsInDay = 86400000
  return Math.ceil(((t.valueOf() - onejan.valueOf()) / millisecsInDay + onejan.getDay() + 1) / 7)
}

export const getWeekOfMonth = (t: Date, exact?: boolean) => {
  const month = t.getMonth(),
    year = t.getFullYear(),
    firstWeekday = new Date(year, month, 1).getDay(),
    lastDateOfMonth = new Date(year, month + 1, 0).getDate(),
    offsetDate = t.getDate() + firstWeekday - 1,
    index = 1, // start index at 0 or 1, your choice
    weeksInMonth = index + Math.ceil((lastDateOfMonth + firstWeekday - 7) / 7),
    week = index + Math.floor(offsetDate / 7)
  if (exact || week < 2 + index) return week
  return week === weeksInMonth ? index + 5 : week
}

const rxd = /^\d+$/
const rxs = /^\d{4}-\d{2}-\d{2}$/
// Simulates PHP's date function
const formatDate = (date: Date | number | string, format: string) => {
  if (!date) {
    return ''
  }
  const type = typeof date
  if (type === 'string' && rxd.test(date as string)) {
    date = new Date(parseInt(date as string, 10))
  } else if (type === 'string') {
    if (rxs.test(date as string)) {
      const d = (date as string).split('-')
      date = new Date(parseInt(d[0]), parseInt(d[1]) - 1, parseInt(d[2]))
    } else {
      date = new Date(Date.parse(date as string))
    }
  } else if (typeof date === 'number') {
    date = new Date(date)
  }
  format = format || 'n/j/y' // default to shortest date
  return format.replace(/(\\?)(.)/g, function (_, esc, chr) {
    return esc === '' && replaceChars[chr] ? replaceChars[chr](date as Date) : chr
  })
}

export default formatDate
