














import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({})
export default class LabelWrap extends Vue {
  @Prop({ default: 'label' }) label!: string
  @Prop({ default: 'active' }) active!: boolean
  @Prop({ default: 'full' }) full!: boolean
  @Prop({ default: '' }) error!: string
  @Prop({ default: 'border-blue-500' }) borderColor!: string
  @Prop({ default: 'text-blue-500' }) textColor!: string
}
