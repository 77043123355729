
























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import SvgMeasureLines from '../SvgMeasureLines.vue'
import PickleballCourtView from './views/PickleballCourtView.vue'
import TennisCourtView from './views/TennisCourtView.vue'
import BasketballCourtView from './views/BasketballCourtView.vue'
import { CourtColor, CourtColorsHash } from '../../helpers/CourtColors'
import { Point2D } from 'shared/src/helpers/geom'

const angled = `transform: perspective(15em) translate3d(0, -20%, 40px)  rotate3d(1, 0, 0, 40deg)`
const flat = `transform: translateY(-10%) perspective(0em) rotateX(0deg)`
const lineStyle = `fill:none;stroke:#666;stroke-width:0.2px;`
const textStyle = `fill:#666;stroke:none;`

export interface MultiViewerData {
  bg: CourtColor

  bbin: CourtColor
  ptln: CourtColor
  key: CourtColor
  keyc: CourtColor

  pkz: CourtColor
  pkk: CourtColor

  tnin: CourtColor
}

export interface MultiViewerSize {
  w: number
  h: number
}

export interface MultiViewerOptions {
  north: 1
  south: 1
  halfCourt: 1
  key: 1
  keyTop: 1
  ptln: 1,
  pkb: 1,
  tns: 1,
  ms: 1
}

interface Pt extends Point2D {
  r?: number
}

interface PLine {
  label?: string
  start: Pt
  end?: Pt
}

@Component({
  components: {
    SvgMeasureLines,
    PickleballCourtView,
    TennisCourtView,
    BasketballCourtView
  }
})
export default class MultiCourtViewer extends Vue {
  @Prop({ default: 188 }) width!: number
  @Prop({ default: 300 }) height!: number
  @Prop({ default: 'angled' }) angle!: 'flat' | 'angled'
  @Prop({ default: () => {
      return { w: 50, h: 84 }
    }
  })
  size!: MultiViewerSize
  @Prop({ default: () => {
      return { north: 1, south: 1, east: 0, west: 0, halfCourt: 1, key: 1, keyTop: 1, ptln: 1 }
    }
  })
  options!: MultiViewerOptions
  @Prop({
    default: () => {
      return {
        bg: CourtColorsHash.none,

        bbin: CourtColorsHash.none,
        key: CourtColorsHash.none,
        keyc: CourtColorsHash.none,
        ptln: CourtColorsHash.none,

        pkin: CourtColorsHash.none,
        pkz: CourtColorsHash.none,
        pkk: CourtColorsHash.none,

        tnin: CourtColorsHash.none
      }
    }
  })

  zones!: MultiViewerData

  lineStyle = lineStyle
  textStyle = textStyle
  angled = angled
  flat = flat
  tn = { w: 0, h: 0 }
  pk = { w: 0, h: 0 }
  key = { w: 0, h: 0 }
  ptx = [0, 0]
  trans = { x: 0, y: 0, width: 0, height: 0 }
  lines?: PLine[]
  trans2 = { x: 0, y: 0, width: 0, height: 0 }
  lines2?: PLine[]

  beforeMount() {
    this.update()
  }

  @Watch('size', { deep: true })
  update(): void {
    console.log('update')
    const s = this.size
    // lines of tennis
    this.tn.w = 36
    this.tn.h = 78
    // pickleball
    this.pk.w = 30
    this.pk.h = 60
    this.key.w = 12
    this.key.h = 19
    this.ptx[0] = s.w * 0.5 - 20.25
    this.ptx[1] = s.w * 0.5 + 20.25
    this.trans.x = 10
    this.trans.y = 10
    this.trans.width = s.w
    this.trans.height = s.h
    this.trans2.x = (s.w - this.pk.w) * 0.5
    this.trans2.y = (s.h - this.pk.h) * 0.5
    this.trans2.width = this.pk.w
    this.trans2.height = this.pk.h
    this.lines = [
      {
        start: { x: s.w + 2, y: 0 },
        end: { x: s.w + 2, y: s.h }
      },
      // {
      //   start: { x: -2, y: 0 },
      //   end: { x: -2, y: s.h * 0.5 }
      // },
      // {
      //   start: { x: s.w - 31, y: s.h + 2 },
      //   end: { x: s.w - 31, y: s.h + 2 }
      // },
      // {
      //   start: { x: 0, y: s.h + 2 },
      //   end: { x: s.w - 44.75, y: s.h + 2 }
      // },
      {
        start: { x: 0, y: s.h + 6 },
        end: { x: s.w, y: s.h + 6 }
      },
      // {
      //   label: 'Side Line',
      //   start: { x: -this.pk.h, y: -2, r: -90 }
      // }
    ]

    this.lines2 = [
      {
        start: { x: -2, y: 8 },
        end: { x: -2, y: this.pk.h - 8 }
      },
      {
        start: { x: this.pk.w * 0.5 - 10, y: this.pk.h + 2 },
        end: { x: this.pk.w * 0.5 + 10, y: this.pk.h + 2 }
      },
      // {
      //   start: { x: this.pk.w + 2, y: this.pk.w },
      //   end: { x: this.pk.w + 2, y: this.pk.w + 7 }
      // },
      // {
      //   start: { x: this.pk.w + 2, y: this.pk.w + 7 },
      //   end: { x: this.pk.w + 2, y: this.pk.h - 8 }
      // },
      {
        label: '36" Net',
        start: { x: s.w - 6, y: this.pk.h * 0.5 - 3 }
      },
      {
        label: 'Base Line',
        start: { x: this.pk.w - 8, y: this.pk.h - 10 }
      },
      {
        label: 'Kitchen',
        start: { x: this.pk.w * 0.5 + 2, y: this.pk.h * 0.5 - 3 }
      },
      {
        label: 'Kitchen',
        start: { x: this.pk.w * 0.5 + 2, y: this.pk.h * 0.5 + 4 }
      },
      {
        label: 'Side Line',
        start: { x: -20, y: 3, r: -90 }
      }
    ]
  }

  get displayAngle(): string {
    return this.angle === 'angled' ? angled : flat
  }
}
