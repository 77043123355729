import { Component, Vue, Prop } from 'vue-property-decorator'
import Rules from '../helpers/Rules'
import { Hash } from '../types/Hash'
import { formatFloat, formatPercent, formatPrice } from '../helpers/numFormat'
import formatDate from '../common/formatDate'

type InputType = 'text' | 'number' | 'tel' | 'url' | 'search' | 'password'
const cleanNumRx = /[^\-0-9.]/g
const cleanPatternRx = /[^0-9]/g

const patternFn = (pattern: string = '(***) ***-****', char: string = '*') => {
  return (v: string): string => {
    // take each number and replace it in the pattern
    v = v.toString().replace(cleanPatternRx, '')
    let str = ''
    let index = 0
    for (let i = 0; i < pattern.length; i += 1) {
      if (pattern[i] === v[index]) {
        index += 1
      }
      if (pattern[i] === char) {
        str += v[index] || char || '0'
        index += 1
      } else {
        str += pattern[i]
      }
    }
    return str
  }
}

export type Formatter = {
  type?: InputType
  in: (str: string | number) => string
  out: (str: string) => string | number
  classes?: string
}
const formatters: Hash<Formatter> = {
  // inchesFormatter - see lib
  number: {
    type: 'number',
    in: (value: string | number): string => value.toString(),
    out: (value: string): number => parseFloat(value.replace(cleanNumRx, '')),
    classes: 'text-right'
  },
  num: {
    type: 'tel',
    in: (value: string | number): string => formatFloat(parseFloat(value as string), 2),
    out: (value: string): number => parseFloat(value.replace(cleanNumRx, '')),
    classes: 'text-right'
  },
  tel: {
    type: 'tel',
    in: patternFn(),
    out: (value: string): string => value.replace(cleanPatternRx, ''),
    classes: 'text-left'
  },
  percent: {
    type: 'tel',
    in: (value: string | number): string => formatPercent((value as number) / 100),
    out: (value: string): number => parseFloat(value.replace(cleanNumRx, '')),
    classes: 'text-right'
  },
  percent2: {
    type: 'tel',
    in: (value: string | number): string => formatPercent(value as number, 4),
    out: (value: string): number => parseFloat(value.replace(cleanNumRx, '')) / 100,
    classes: 'text-right'
  },
  date: {
    type: 'text',
    in: (value: string | number): string => formatDate(value || Date.now(), 'n/j/Y'),
    out: (value: string): number => {
      const p: (number | string)[] = value.split('/')
      p[0] = parseFloat(p[0] as string)
      p[1] = parseFloat(p[1] as string)
      p[2] = parseFloat(p[2] as string)
      const currentYear = new Date().getFullYear()
      p[0] = p[0] < 1 ? 1 : p[0] > 12 ? 12 : p[0]
      p[1] = p[1] < 1 ? 1 : p[1] > 31 ? 31 : p[1]
      p[2] = p[2] < 1 ? currentYear : p[2]
      return new Date(p[2], p[0] - 1, p[1]).getTime()
    },
    classes: 'text-right'
  },
  currency: {
    type: 'tel',
    in: (value: string | number): string => formatPrice(parseFloat((value + '').replace('$', '')), 2),
    out: (value: string): number => parseFloat(value.replace(cleanNumRx, '')),
    classes: 'text-right'
  },
  currency6: {
    type: 'tel',
    in: (value: string | number): string => formatPrice(parseFloat((value + '').replace('$', '')), 6),
    out: (value: string): number => parseFloat(value.replace(cleanNumRx, '')),
    classes: 'text-right'
  },
  password: {
    type: 'password',
    in: (value: string | number): string => value as string,
    out: (value: string): string => value
  },
  default: {
    type: 'text',
    in: (value: string | number): string => value as string,
    out: (value: string): string => value
  }
}

@Component
export default class TextField extends Vue {
  @Prop({ default: '' }) name!: string
  @Prop({ default: 'text' }) type!: 'text' | 'number' | 'currency' | 'password'
  @Prop({ default: '' }) label!: string
  @Prop({ default: '' }) value!: string
  @Prop({ default: null }) formatter!: Formatter
  @Prop({ default: '' }) error!: string
  @Prop({ default: 'blue-500' }) highlight!: string
  @Prop({ default: 'bg-white' }) bg!: string
  @Prop({ default: null }) rules!: Rules[]
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: 1 }) step: number

  @Prop({ default: null }) restrictions!: string[]

  focused = false
  get full(): boolean {
    return !this.getOutput()
  }

  get fmtr(): Formatter {
    return this.formatter || formatters[this.type] || formatters.default
  }

  get displayValue(): string {
    const result = this.fmtr.in(this.value) || this.value
    return result
  }

  mounted(): void {
    this.onInputChange({ target: this.$refs.input as Vue })
  }

  classes(): string {
    return this.bg
  }

  inputClasses(): string {
    return `${this.full || this.focused ? '-mt-1' : '-mt-2'}`
  }

  getOutput(el?: HTMLInputElement): string | number {
    el = el || (this.$refs.input as HTMLInputElement)
    const str = el?.value || ''
    const v = this.fmtr.out(str)
    return v !== '' && v !== undefined ? v : str
  }

  applyRestrictions(value: string | number): string | number {
    if (this.restrictions) {
      const v = value.toString().toLowerCase()
      for (let i = 0; i < this.restrictions.length; i += 1) {
        const term: string = this.restrictions[i].toLowerCase()
        if (term.indexOf(v) !== -1) {
          return term
        }
      }
      return this.restrictions[0] // force if there are restrictions
    }
    return value
  }
  onInputChange(e: { target: Vue | Element | Vue[] | Element[] }): void {
    const el = e.target as HTMLInputElement
    this.$emit('input', this.getOutput(el))
  }

  onBlur(): void {
    const el = this.$refs.input as HTMLInputElement
    const v = this.applyRestrictions(el.value)
    if (el.value !== v) {
      el.value = v as string
    }
    this.$emit('blur', this.getOutput())
  }
}
